(function () {
    var init_datepickers = function init_datepickers() {
        $("input.date-time-picker, input.date-picker, input.datepicker").not(".has-date-picker").each(function () {
            "use strict";

            var include_time = $(this).is(".date-time-picker");
            var format = include_time ? "MM/DD/YYYY HH:mm" : "MM/DD/YYYY";
            var other_format = (include_time ? "MM/DD/YYYY" : "MM/DD/YYYY HH:mm");
            var html5_input_type = include_time ? 'datetime-local' : 'date';
            var val = $(this).val().split("-").join("/").trim();
            var extra_formats = [other_format, 'YYYY/MM/DD HH:mm:ss', 'MM/DD/YYYY HH:mm:ss', 'YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss'];
            var m = moment(val, [format].concat(extra_formats), true);
            var options = {
                sideBySide: true,
                format: format,
                extraFormats: extra_formats,
                keepInvalid: !!$(this).is(".keep-invalid"),
                inline: !!$(this).is(".always-visible"),
            };

            if ($(this).is(".always-visible")) {
                $(this).hide();
            }

            if ($(this).data("min-date")) {
                options.minDate = moment($(this).data("min-date"));
            }

            if ($(this).data("max-date")) {
                options.maxDate = moment($(this).data("max-date"));
            }

            if (Modernizr.inputtypes[html5_input_type] && window.matchMedia('only screen and (max-device-width : 736px)').matches) {
                if (m.isValid()) {
                    if (include_time) {
                        val = m.toISOString().slice(0, -1);
                    } else {
                        val = m.format("YYYY-MM-DD");
                    }
                } else {
                    val = "";
                }
                $(this).val(val);
                $(this).attr("type", html5_input_type);
                $(this).on('input change', function () {
                    // This fixes an issue with Safari where the value would include seconds but isn't supposed to, thus triggering a validation error.
                    // This just removes the seconds from the value.
                    $(this).val($(this).val().substr(0, 16));
                });
            } else {
                if (m.isValid()) {
                    options.defaultDate = m;
                    $(this).val("");
                } else {
                    val = options.keepInvalid ? $(this).val() : "";
                    $(this).val(val);
                }
                $(this).datetimepicker(options);
                $(this).addClass("has-date-picker");
            }
        });

        /* Plugin for time-picker. */
        $("input.time-picker, input.timepicker").not(".has-date-picker").each(function () {
            "use strict";

            var options = {
                format: "HH:mm"
            };

            if (Modernizr.inputtypes['time'] && window.matchMedia('only screen and (max-device-width : 736px)').matches) {
                $(this).val(moment($(this).val()).format("HH:mm"));
                $(this).attr("type", "time");
            } else {
                $(this).val(moment($(this).val().split("-").join("/")).format("HH:mm"));

                if ($(this).data("min-date")) {
                    options.minDate = moment($(this).data("min-date"));
                }

                if ($(this).data("max-date")) {
                    options.maxDate = moment($(this).data("max-date"));
                }

                $(this).datetimepicker(options);
                $(this).addClass("has-date-picker");
            }
        });
    }
    init_datepickers();
    window.init_datepickers = init_datepickers;
})();