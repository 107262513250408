function setup_autohide() {
    "use strict";

    $('[data-autohide-input]').not('.autohidden').each(function () {
        var $el = $(this),
            selector = $el.data('autohide-input'),
            parent_selector = $el.data('autohide-parent'),
            values = $el.data('autohide-value') ? $el.data('autohide-value') : $el.data('autohide-values'),
            values_not = $el.data('autohide-value-not') ? $el.data('autohide-value-not') : $el.data('autohide-values-not'),
            easing = $el.data('autohide-easing'),
            $input;
        var process_change = function (use_show) {
            var hide = false;
            var val = [$input.val()];
            var trigger = function () {
                $input.trigger("autohide.change");
            };

            if ($input.is(":checkbox")) {
                val = [$input.is(":checked") ? "1" : "0"];
            }

            if ($input.is(":radio")) {
                val = [$('[name="' + $input.attr("name") + '"]:checked').val()];
            }

            if ($input.prop("multiple")) {
                val = $input.val();
                if (val === null) {
                    val = [];
                }
            }

            if (values !== undefined) {
                values = ('' + values).split(",");
                $.each(values, function (i, subvalue) {
                    if (val.indexOf(subvalue) !== -1) {
                        hide = true;
                    }
                });
            }

            if (values_not !== undefined) {
                values_not = ('' + values_not).split(",");
                hide = true;
                $.each(values_not, function (i, subvalue) {
                    if (val.indexOf(subvalue) !== -1) {
                        hide = false;
                    }
                })
            }

            if (hide) {
                if (use_show === true) {
                    $el.hide(0, trigger);
                } else {
                    if (easing === "fade") {
                        $el.fadeOut('fast', trigger);
                    } else if (easing === "slide") {
                        $el.slideUp('fast', trigger);
                    } else {
                        $el.hide(0, trigger);
                    }
                }
            } else {
                if (use_show === true) {
                    $el.show(0, trigger);
                } else {
                    if (easing === "fade") {
                        $el.fadeIn('fast', trigger);
                    } else if (easing === "slide") {
                        $el.slideDown('fast', trigger);
                    } else {
                        $el.show(0, trigger);
                    }
                }
            }
        };


        if (parent_selector) {
            $input = $el.parents(parent_selector).find(selector);
        } else {
            $input = $(selector);
        }

        if (typeof(values_not) === "undefined" && typeof(values) === "undefined") {
            console.error("No values set!", $el);
        } else {
            $input.on('change', process_change);
            process_change(true);
            $el.addClass("autohidden");
        }
    });
}

setup_autohide();