(function () {
    "use strict";
    var State = {}, Project = {}, UTIL = {};

    State = {};

    Project = {
        common: {
            init: function () {

            },
            finalize: function () {

            }
        },
        pages: {
            init: function () {

            },
            login: function () {

            }
        }
    };

    window.Project = Project;

    UTIL = {
        fire: function (func, funcname, args) {
            var namespace = Project;
            funcname = (funcname === undefined) ? 'init' : funcname;
            if (func !== '' && namespace[func] && typeof namespace[func][funcname] == 'function') {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            var bodyId = document.body.id;
            UTIL.fire('common');
            $.each(document.body.className.split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, bodyId);
            });
            UTIL.fire('common', 'finalize');
        }
    };

    UTIL.loadEvents();
})();